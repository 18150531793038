<!--
<div *ngIf="notClicked" class="hover_bkgr_fricc">
  <span class="helper"></span>
  <div>
    <div (click)="closeBanner()" class="popupCloseButton">&times;</div>
    <img src="../../assets/images/drachenfestBanner.png">
    <a href="https://www.drachenfest-muensingen.de/"></a>
  </div>
</div>
  -->
<div *ngIf="notClicked" class="hover_bkgr_fricc">
  <span class="helper"></span>
  <div>
    <div (click)="closeBanner()" class="popupCloseButton">&times;</div>
    <img src="../../assets/images/oldtimerBanner.jpg">
    <a href="https://www.oldtimerfest-muensingen.de/"></a>
  </div>
</div>
